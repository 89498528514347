import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL, PROJECT_KEY } from './Config';

const Regi = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        phoneNumber: '',
      });
    const fullPhoneNumber = `${formData.phoneNumber}`;
  

  const handlePhoneNumberChange = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Make API call here using fetch or any other HTTP library
    try {
        const response = await axios.post(`${API_BASE_URL}/auth/sendOtp`, {
            mobile_number: fullPhoneNumber,
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'project_secret_key': PROJECT_KEY,
                }

            }

        );
      // Handle the response, for example:
      if (response.status === 200) {
        navigate('/otpverify', { state: { fullPhoneNumber: formData.phoneNumber } });
    } else {
        throw new Error('Request failed');
        // setLgShow(false);
    }
    } catch (error) {
    }
  };

  return (
    <>
      <div className='container-fluid crop_login_page'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'></div>
            <div className='col-md-6'>
              <div className="card kart_login">
                <h1 className='login_wel'>Welcome</h1>
                <p className='login_we'>India's largest platform for buying & selling farm-yields at best prices</p>
                <form onSubmit={handleSubmit}>
                  <center>
                    <div className='login_phone'>
                      <PhoneInput
                        placeholder='Phone Number'
                        value={formData.phoneNumber}
                        onChange={handlePhoneNumberChange}
                        autoComplete='off'
                        defaultCountry="IN"
                        limitMaxLength="10"
                        required
                      />
                      <button type="submit" className="login_button btn">
                        Submit
                      </button>
                    </div>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Regi;
