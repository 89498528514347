import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

export function AppContextProvider({ children }) {
  const [category_id, setCategoryId] = useState(null);

  return (
    <AppContext.Provider value={{ category_id, setCategoryId }}>
      {children}
    </AppContext.Provider>
  );
}
