import React, { useState } from 'react'
import layer from './Image/Mask group.jpg'
import { Link } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import Modal from 'react-modal';
import axios from 'axios';
import { API_BASE_URL, PROJECT_KEY } from './Config';
import { useNavigate } from 'react-router-dom';
import './Custom.css'

const Profile = () => {

    const isLoggedIn = window.localStorage.getItem('isLoggedIn') === 'true';
    const tokenNo = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    const openLogoutModal = () => {
        setShowLogoutModal(true);
    };

    const closeLogoutModal = () => {
        setShowLogoutModal(false);
    };

    const handleLogout = () => {

        openLogoutModal();
    };

    const confirmLogout = async () => {
        try {
            // Make an API call to logout endpoint using Axios
            const response = await axios.post(
                `${API_BASE_URL}/user/deleteUser`,
                { user_id: userId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'project_secret_key': PROJECT_KEY,
                    },
                }
            );

            if (response.status === 201) {
                setShowAlert(true);
                localStorage.clear();
                setTimeout(() => {
                    navigate('/');
                    localStorage.clear();
                }, 3000);
            } else {
            }
        } catch (error) {
        }
    };

    const cancelLogout = () => {

        closeLogoutModal();
    };

    const handleDeleteAcount = () => {
        navigate('/deleteaccount')
    };
    const handleDeleteConfirmation = () => {
        const params = {
            user_id: userId,
        }
        axios.post(`${API_BASE_URL}/user/deleteUser`, params, {
            headers: {
                'Content-Type': 'application/json',
                'project_secret_key': PROJECT_KEY,
                'Authorization': 'Bearer ' + tokenNo,
            },

        })
            .then((response) => {
                if (response.status === 200) {

                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        // setShowDeleteModal(false);
    };

    return (
        <>
            <div className='container-fluid profile_container'>
                <div className='profile_section'>
                    <div className='top_image'>
                        <img className='pr_image' src={layer} alt='top pic' />
                    </div>
                    <div className='container my-5'>
                        <div className='row profile_buttons'>
                            <div className='col-md-3 col-sm-6'>
                                {isLoggedIn ? (
                                    <button onClick={handleLogout} className='profile_logout_button'>
                                        <div className='account_tab'>
                                            <i className="bi bi-power profile_icon"></i>
                                            <p className='profile_para'>Delete Account</p>
                                            <i className="bi bi-chevron-right profile_icon ms-auto"></i>
                                        </div>
                                    </button>
                                ) : (
                                    <Link to='/' className='link_profile'>
                                        <div className='account_tab'>
                                            <i className="bi bi-power profile_icon"></i>
                                            <p className='profile_para'>Login</p>
                                            <i className="bi bi-chevron-right profile_icon ms-auto"></i>
                                        </div>
                                    </Link>
                                )}

                                <Modal show={showLogoutModal} onHide={() => handleDeleteConfirmation(false)}></Modal>
                                <Modal
                                    isOpen={showLogoutModal}
                                    onRequestClose={cancelLogout}
                                    contentLabel="Logout Modal"
                                >
                                    {showAlert && (
                <div className="alert new_add_alert alert-dismissible fade show" role="alert">
                    Your Account Has Deleted
                </div>
            )}
                                    <div className="modal-content">
                                        <p className='logout_modal_p'>Are you sure you want to Delete your Account?</p>
                                        <div className='account_tab log_btn_out'>
                                            <button className='profile_logout_button' onClick={confirmLogout}>Yes</button>
                                        </div>
                                        <div className='account_tab log_btn_out'>
                                            <button className='profile_logout_button' onClick={cancelLogout}>No</button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
