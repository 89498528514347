import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router';
import { API_BASE_URL, PROJECT_KEY } from './Config';

const OtpVerify = () => {

  const location = useLocation();
  const phoneNumber = location.state ? location.state.fullPhoneNumber : null;

  const navigate = useNavigate();
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const [verificationStatus, setVerificationStatus] = useState('');
  const handleChange = (e, index) => {
    const updatedOTP = [...otp];
    updatedOTP[index] = e.target.value;
    setOTP(updatedOTP);

    if (e.target.value === '' && index > 0) {
      const prevIndex = index - 1;
      const prevInput = document.getElementById(`otpInput-${prevIndex}`);
      if (prevInput) {
        prevInput.focus();
      }
    }

    if (e.target.value && index < otp.length - 1) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(`otpInput-${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleVerify = async () => {
    try {
      const otpCode = otp.join('');
      const response = await axios.post(
        `${API_BASE_URL}/auth/login`,
        { mobile_number: phoneNumber, otp: otpCode },
        {
          headers: {
            'Content-Type': 'application/json',
            'project_secret_key': PROJECT_KEY,
          },
        }
      );
      if (response.status === 200) {
        localStorage.setItem('user_id', response.data.data.user_id);
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem("isLoggedIn", true)
        setVerificationStatus('Verification successful');
        navigate('/profile');

      } else {
        setVerificationStatus('Verification failed out');
      }
    } catch (error) {
      setVerificationStatus('Verification failed');
    }
  };

  return (
    <div>
      <div className="otp-input-container">
      <h4 className='login_wel'> Verify your number</h4>
        <p className='login_detail'>We have sent you SMS with a code to your number</p>
        <p className='login_detail'>{phoneNumber}</p>
        {otp.map((digit, index) => (
          <input
            className='otp_verify'
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(e, index)}
            id={`otpInput-${index}`}
          />
        ))}
      </div>
      <center>
        <button className='otp_verify_btn btn' onClick={handleVerify}>
          Verify OTP
        </button>
      {verificationStatus && <p>{verificationStatus}</p>}
      </center>
    </div>
  );
};

export default OtpVerify;
