import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import './Custom.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AppContextProvider } from './AppContext';
import Register from './Register';
import OtpVerify from './OtpVerify'
import Profile from './Profile';
function App() {
 
  // const isLoggedIn = window.localStorage.getItem('isLoggedIn') === 'true';
  return (<AppContextProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/otpverify" element={<OtpVerify />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Router>
    </AppContextProvider>
  );
}

export default App;
